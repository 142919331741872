
import Vue from "vue";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import S3Image from "@/components/aws/S3Image.vue";
import RadialApiClient from "@/api/RadialApiClient";
import dayjs from "dayjs";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import ElementDatePicker from "@/components/element/DatePicker.vue";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import Loading from "@/components/vuetify/Loading.vue";
import { AlertDetail, ProductTrendTableHeader, AdminUserDetail, SearchEntriesInSkuList, User } from "@/store/model";
import {
  Store,
  AlertMaster,
  Company,
  AdminUser,
  SkuListDataEntity,
  Sku,
  AlertWithMaster,
  DetailTrendItemEntity,
  Warehouse,
  SkuDetailTrendEntity,
  S3KeySkuMainEntity,
  SkuRequestData,
  Action,
  SkuFilterInfoEntity,
  CustomActionMaster,
  ProductStatus,
  FilterInfoDataEntity,
} from "@/api/entities";
import AuthClient from "@/api/AuthClient";
import mixin from "@/mixin/mixin";
import { ActionType, CreateStockActionDetailDto, SkuListActionAddSkusDto, SkuListInputDto } from "@/api/dto";
import { Constant } from "@/store/constant";
import store from "@/store";
import { CreateActionDto } from "@/api/dto";

export interface DataType {
  companyAdminUser: Company | AdminUser | null;
  isLoading: boolean;
  showingAlert: boolean;
  alertTitle: string;
  alertMessage: string;
  alertType: string;
  isTableLoading: boolean;
  options: {
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
  };
  tableFormat: number;
  alertMasters: AlertMaster[];
  stores: Store[];
  skuFilterInfo: SkuFilterInfoEntity;
  productFilterStatusInput: { id: number; name: string; code: ProductStatus }[]; // ステータスのフィルタ一覧
  warehouses: Warehouse[];
  rangeDates: string[];
  searchEntries: SearchEntriesInSkuList;
  skuListDatas: SkuListDataEntity[];
  selectedItems: SkuListDataEntity[];
  currentIndex: number | null;
  skuMaxCount: number;
  isDeleting: boolean;
  actionOption: string;
  adminUserDetails: AdminUserDetail[];
  selectedSku: SkuListDataEntity | null;
  skuDetailTableHeader: ProductTrendTableHeader[];
  skuDetailTableInfo: SkuDetailTrendEntity | null;
  skuImages: S3KeySkuMainEntity[] | null;
  showingRequestActionDialog: boolean;
  // TODO: SkuActionTypeに変更
  selectedActionRequestType: ActionType;
  selectedActionType: ActionType; // バックエンド型定義後修正
  addRequestInfo: { sourceId: string | null; destinationId: string | null; quantity: number | null };
  createRequestDialogPageNumber: number;
  requestSkuId: string | null;
  skuRequests: SkuRequestData[];
  selectedSkuRequests: SkuRequestData[];
  showingAddRequestIntoActionDialog: boolean;
  actions: Action[];
  inputActionName: string;
  selectedAction: Action | null;
  showingCreateActionDialog: boolean;
  showingAddSkuIntoActionDialog: boolean;
  createActionDialogPageNumber: number;
  addActionListInfo: {
    title: string | null;
    offRatio: string | null;
    managerId: string | null;
    executedDate: Date | null;
  };
  inputCustomActionName: string;
  selectedCustomActionMaster: CustomActionMaster | null;
  customActionMasters: CustomActionMaster[];
  addProductIntoActionDialogPageNumber: number; // 0: アクションタイプの選択, 1: アクション選択
  createdActionId: string | null;
  productFilterStoreInput: FilterInfoDataEntity[]; // 集計対象外を「集計対象外」としてまとめた店舗一覧
}

export default Vue.extend({
  name: "SkuList",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    S3Image,
    FilterChipButton,
    ElementRangeDatePicker,
    ElementDatePicker,
    ConfirmationDialog,
    Loading,
  },
  data(): DataType {
    return {
      companyAdminUser: null,
      isLoading: false,
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
      isTableLoading: false,
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ["sumSalesCount"],
        sortDesc: [true],
      },
      tableFormat: 0,
      alertMasters: [],
      stores: [],
      skuFilterInfo: {
        brands: [],
        seasons: [],
        categories: [],
        attribute1: [],
        attribute2: [],
        attribute3: [],
        stores: [],
        colors: [],
        sizes: [],
        alertMasters: [],
      },
      productFilterStatusInput: Constant.productStatusInput,
      warehouses: [],
      rangeDates: [dayjs().subtract(6, "day").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")],
      searchEntries: {
        brands: [],
        seasons: [],
        categories: [],
        attribute1s: [],
        attribute2s: [],
        attribute3s: [],
        colors: [],
        sizes: [],
        alertMasters: [],
        stores: [],
        statuses: [],
      },
      skuListDatas: [],
      selectedItems: [],
      currentIndex: null,
      skuMaxCount: 0,
      isDeleting: false,
      actionOption: "アクション",
      adminUserDetails: [],
      selectedSku: null,
      skuDetailTableHeader: Constant.productTrendTableHeader,
      skuDetailTableInfo: null,
      skuImages: [],
      showingRequestActionDialog: false,
      selectedActionRequestType: "STOCKMOVING",
      selectedActionType: "CUSTOM",
      addRequestInfo: { sourceId: null, destinationId: null, quantity: null },
      createRequestDialogPageNumber: 0,
      requestSkuId: null,
      skuRequests: [],
      selectedSkuRequests: [],
      showingAddRequestIntoActionDialog: false,
      actions: [],
      inputActionName: "",
      selectedAction: null,
      showingCreateActionDialog: false,
      showingAddSkuIntoActionDialog: false,
      createActionDialogPageNumber: 0,
      addActionListInfo: { title: null, offRatio: null, managerId: null, executedDate: null },
      inputCustomActionName: "",
      selectedCustomActionMaster: null,
      customActionMasters: [],
      addProductIntoActionDialogPageNumber: 0,
      createdActionId: null,
      productFilterStoreInput: [],
    };
  },
  watch: {
    showingAlert: {
      handler() {
        if (!this.showingAlert) {
          this.actionOption = "アクション";
        }
      },
    },
    showingCreateActionDialog: {
      handler() {
        if (!this.showingCreateActionDialog && !this.showingAlert) {
          this.actionOption = "アクション";
        }
      },
    },
    showingAddSkuIntoActionDialog: {
      handler() {
        if (!this.showingAddSkuIntoActionDialog && !this.showingAlert) {
          this.actionOption = "アクション";
        }
      },
    },
  },
  computed: {
    user(): User | null {
      return store.state.user ?? null;
    },
    actionOptions(): string[] {
      return this.canUpdate ? ["アクション作成", "アクションへ追加", "SKU削除"] : []; // アクションは以降のversionで実装
    },
    isRoot(): boolean {
      return this.user?.sub === this.user?.companyId;
    },
    minDate(): string {
      return dayjs().subtract(3, "year").format("YYYY-MM-DD");
    },
    filterDateFrom(): string {
      if (dayjs(this.rangeDates[1]).diff(dayjs(this.rangeDates[0]), "days") > 0) {
        return this.rangeDates[0];
      } else {
        return this.rangeDates[1];
      }
    },
    filterDateTo(): string {
      if (dayjs(this.rangeDates[1]).diff(dayjs(this.rangeDates[0]), "days") > 0) {
        return this.rangeDates[1];
      } else {
        return this.rangeDates[0];
      }
    },
    attributesLength(): number {
      return (
        this.searchEntries.attribute1s.length +
        this.searchEntries.attribute2s.length +
        this.searchEntries.attribute3s.length
      );
    },
    headers(): { text: string; value: string; align: string; class: string; sortable?: boolean }[] {
      const result: {
        text: string;
        value: string;
        align: string;
        class: string;
        sortable?: boolean;
      }[] = [
        {
          text: "",
          value: "image",
          align: "",
          class: "sku-list-image-header",
          sortable: false,
        },
        {
          text: "品番情報",
          value: "info",
          align: "left",
          class: "body-1-noto-sans radial-assistant-type-70 sku-list-product-header",
          sortable: false,
        },
        {
          text: "カラー / サイズ",
          value: "colorSize",
          align: "left",
          class: "body-1-noto-sans radial-assistant-type-70 sku-list-color-size-header",
          sortable: false,
        },
      ];
      Array.prototype.push.apply(
        result,
        this.tableFormat === 0
          ? [
              {
                text: "売上",
                value: "sumSalesCount",
                align: "right",
                class: "body-1-noto-sans radial-assistant-type-70 sku-list-header",
              },
              {
                text: "在庫（店舗 / 倉庫）",
                value: "sumStockCount",
                align: "left",
                class: "body-1-noto-sans radial-assistant-type-70 sku-list-header pl-12",
              },
            ]
          : [
              {
                text: "売上",
                value: "sumSalesPrice",
                align: "right",
                class: "body-1-noto-sans radial-assistant-type-70 sku-list-header",
              },
              {
                text: "在庫（店舗 / 倉庫）",
                value: "sumStockPrice",
                align: "left",
                class: "body-1-noto-sans radial-assistant-type-70 sku-list-header pl-12",
              },
            ]
      );
      Array.prototype.push.apply(result, [
        {
          text: "消化率",
          value: "digestionRate",
          align: "right",
          class: "body-1-noto-sans radial-assistant-type-70 sku-list-header",
        },
        {
          text: "消化週数",
          value: "storageLife",
          align: "right",
          class: "body-1-noto-sans radial-assistant-type-70 sku-list-header",
        },
        {
          text: "アラート",
          value: "alertDatas",
          align: "left",
          class: "body-1-noto-sans radial-assistant-type-70 sku-list-header",
          sortable: false,
        },
        {
          text: "アクション",
          value: "actions",
          align: "left",
          class: "body-1-noto-sans radial-assistant-type-70 sku-list-header",
          sortable: false,
        },
        {
          text: "",
          value: "option",
          align: "left",
          class: "body-1-noto-sans radial-assistant-type-70 sku-list-header",
          sortable: false,
        },
      ]);
      return result;
    },
    pageRange(): number[] {
      const start = this.options.itemsPerPage * (this.options.page - 1) + 1;
      let end = this.options.itemsPerPage * this.options.page;
      if (end > this.skuMaxCount) {
        end = this.skuMaxCount;
      }
      return [start, end];
    },
    skuDetailTableHeight(): string {
      if (this.skuDetailTableInfo) {
        return this.skuDetailTableInfo.items.length * 40 > window.innerHeight - 410
          ? (window.innerHeight - 410).toString()
          : "384";
      }
      return "384";
    },
    filteredActions(): Action[] {
      return this.actions
        .filter((item) => item.type === this.selectedActionType)
        .filter((item) => ~item.title.indexOf(this.inputActionName));
    },
    filteredCustomActionMasters(): CustomActionMaster[] {
      return this.customActionMasters.filter((item) => ~item.name.indexOf(this.inputCustomActionName));
    },
    isAddActionButtonDisabled(): boolean {
      switch (this.selectedActionType) {
        case "CUSTOM":
          return !(this.selectedCustomActionMaster && this.addActionListInfo.title && this.addActionListInfo.managerId);
        case "INTERSTORETRANSFER":
          return !(this.addActionListInfo.title && this.addActionListInfo.managerId);
        default:
          return false;
      }
    },
    notSameCustomAction(): boolean {
      return !this.customActionMasters.some((item) => item.name === this.inputCustomActionName);
    },
    modalButtonName(): string {
      return this.actionOption === "SKU削除" && this.selectedItems.length > 0 && !this.isDeleting
        ? "削除"
        : (this.actionOption === "アクション作成" || this.actionOption === "アクションへ追加") &&
          this.alertType !== "error" &&
          this.selectedActionType !== "INTERSTORETRANSFER"
        ? "閲覧"
        : "";
    },
    dateRangeError(): boolean {
      if (dayjs(this.filterDateTo).diff(dayjs(this.filterDateFrom), "year") >= 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    showAlert(title: string, message: string, type: string) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    async searchButtonDidClick() {
      this.options.page = 1;
      await this.search();
    },
    async search() {
      if (this.dateRangeError) {
        return;
      }
      this.isTableLoading = true;
      const input: SkuListInputDto = {
        start: dayjs(this.filterDateFrom).startOf("day").toDate(),
        end: dayjs(this.filterDateTo).endOf("day").toDate(),
        offset: this.pageRange[0] - 1,
        limit: this.options.itemsPerPage,
        sort: this.options.sortBy[0],
        isDesc: this.options.sortDesc[0],
        brandIds: this.searchEntries.brands.map((item) => {
          return item.id as number;
        }),
        seasonIds: this.searchEntries.seasons.map((item) => {
          return item.id as number;
        }),
        categoryIds: this.searchEntries.categories.map((item) => {
          return item.id as number;
        }),
        attribute1Ids: this.searchEntries.attribute1s.map((item) => {
          return item.id as string;
        }),
        attribute2Ids: this.searchEntries.attribute2s.map((item) => {
          return item.id as string;
        }),
        attribute3Ids: this.searchEntries.attribute3s.map((item) => {
          return item.id as string;
        }),
        colorIds: this.searchEntries.colors.map((item) => {
          return item.id as string;
        }),
        sizeIds: this.searchEntries.sizes.map((item) => {
          return item.id as string;
        }),
        alertMasterIds: this.searchEntries.alertMasters.map((item) => {
          return item.id as number;
        }),
        storeIds: this.searchEntries.stores.map((store) => (typeof store.id === "string" ? store.id : "inoperative")),
        productStatus: this.searchEntries.statuses.map((item) => item.code),
      };
      const skuList = await RadialApiClient.listSkuList(input);
      if (skuList) {
        this.skuMaxCount = skuList.skuMaxCount;
        this.skuListDatas = skuList.skuListDatas;
        const skuImages = await RadialApiClient.getSkuMainImages({
          skuIds: this.skuListDatas.map((item) => item.skuId),
        });
        this.skuImages = skuImages;
      }

      const search = JSON.stringify(this.searchEntries);
      const rangeDates = JSON.stringify(this.rangeDates);
      if (
        (await AuthClient.checkAuth(this.isAdmin)) &&
        (search !== this.$route.query.search || rangeDates !== this.$route.query.rangeDates)
      ) {
        this.$router.push({
          name: this.isAdmin ? "AdminSkuList" : "SkuList",
          query: { search, rangeDates },
        });
      }
      this.isTableLoading = false;
    },
    async clearEntries() {
      this.searchEntries = {
        brands: [],
        seasons: [],
        categories: [],
        attribute1s: [],
        attribute2s: [],
        attribute3s: [],
        colors: [],
        sizes: [],
        alertMasters: [],
        stores: [],
        statuses: [],
      };
      await this.searchButtonDidClick();
    },
    getIndex(item: SkuListDataEntity): number {
      const start = this.options.itemsPerPage * (this.options.page - 1) + 1;
      return start + this.skuListDatas.indexOf(item);
    },
    selectSku(item: SkuListDataEntity) {
      if (this.selectedItems.includes(item)) {
        // item が含まれていたら削除する
        this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem !== item);
      } else {
        this.selectedItems.push(item);
      }
    },
    getS3Key(id: string): string {
      return this.skuImages?.find((item) => item.skuId === id)?.s3Key ?? "";
    },
    getSalesDates(sku: SkuListDataEntity): string {
      const start: string = sku.salesStartedAt ? dayjs(sku.salesStartedAt).format("YYYY/MM/DD") : "-";
      const end: string = sku.salesEndedAt ? dayjs(sku.salesEndedAt).format("YYYY/MM/DD") : "-";
      return `${start} 〜 ${end}`;
    },
    showDetailInNewTab(productId: string) {
      const routeData = this.$router.resolve({
        name: this.isAdmin ? "AdminProductDetail" : "ProductDetail",
        params: { productId },
      });
      window.open(routeData.href, "_blank");
    },
    showRequestInNewTab() {
      const routeData = this.$router.resolve({
        name: "AdminActionRequest",
      });
      window.open(routeData.href, "_blank");
    },
    getAttributeTitle(): string {
      if (this.attributesLength === 1) {
        // 選択されている属性が１つだけのとき、その名前を表示
        if (this.searchEntries.attribute1s.length > 0) {
          return this.searchEntries.attribute1s[0].name;
        }
        if (this.searchEntries.attribute2s.length > 0) {
          return this.searchEntries.attribute2s[0].name;
        }
        if (this.searchEntries.attribute3s.length > 0) {
          return this.searchEntries.attribute3s[0].name;
        }
      } else {
        return "属性";
      }
      return "";
    },
    getAlertDetail(alertMasterId: number): AlertDetail {
      const alert = this.alertMasters
        .filter((item) => {
          return item.id === alertMasterId;
        })
        .shift();
      if (alert) {
        return {
          number: alert.id,
          name: alert.name,
          color: alert.prominenceColor,
          storeIntegration: alert.storeIntegration,
          description: alert.description,
          comment: alert.comment,
        };
      }
      return {
        number: -1,
        name: "",
        color: "",
        storeIntegration: false,
        description: "",
        comment: "",
      };
    },
    async deleteAlert(index: number, alert: AlertWithMaster) {
      this.isLoading = true;
      const updatedAlert = await RadialApiClient.userDeleteAlert(alert.alertId);
      if (updatedAlert) {
        // searchをかけるのではなく、該当の行（プロダクト）から削除したアラートを更新する
        const selectedAlert = this.skuListDatas[index]?.alertDatas.find(
          (alertData) => alertData.alertId === alert.alertId
        );
        if (selectedAlert) {
          selectedAlert.userDeleted = true;
        }
      } else {
        this.showAlert("", "エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
      this.isLoading = false;
    },
    async paginationButtonDidClick(index: number) {
      if (index === 0) {
        this.options.page -= 1;
        await this.search();
      } else {
        this.options.page += 1;
        await this.search();
      }
    },
    openAction(actionId: string) {
      const routeData = this.$router.resolve({
        name: "AdminActionList",
        params: { actionId },
      });
      window.open(routeData.href, "_blank");
    },
    async openSkuDetail(sku: SkuListDataEntity) {
      this.selectedSku = sku;
      this.skuDetailTableInfo = await RadialApiClient.getSkuDetailTrends(sku.skuId);
      (this.skuDetailTableInfo?.items ?? []).sort((a, b) => {
        const aName = this.getChanelName(a.storeId, a.warehouseId);
        const bName = this.getChanelName(b.storeId, b.warehouseId);
        if (a.warehouseId || a.storeId === "集計対象外" || aName > bName) {
          return 1;
        } else {
          return -1;
        }
      });
    },
    closeSkuDetail() {
      this.selectedSku = null;
      this.skuDetailTableInfo = null;
    },
    // 商品詳細テーブル関係はProductDetailTrend.vueのコピペ
    customSort(items: DetailTrendItemEntity[], index: string[], isDesc: boolean[]) {
      const ascValue = isDesc[0] ? -1 : 1;
      items.sort((a, b) => {
        switch (index[0]) {
          case "storageLife":
            if (a.storageLife === "∞週") return ascValue;
            if (b.storageLife === "∞週") return -ascValue;
            return (parseInt(a.storageLife) - parseInt(b.storageLife)) * ascValue;
          case "digestionRate":
            if (a.digestionRate === "-") return -ascValue;
            if (b.digestionRate === "-") return ascValue;
            return (parseInt(a.digestionRate) - parseInt(b.digestionRate)) * ascValue;
          case "stockCount":
            return (a.stockCount - b.stockCount) * ascValue;
          case "unitPrice":
            if (this.tableFormat === 0) {
              return (a.salesCounts[0] - b.salesCounts[0]) * ascValue;
            } else {
              return (a.salesPrices[0] - b.salesPrices[0]) * ascValue;
            }
          default:
            return 0;
        }
      });
      return items;
    },
    getServiceLogo(storeId: string, warehouseId: string): string {
      const serviceId =
        warehouseId === null
          ? this.stores.find((store) => store.id === storeId)?.serviceId
          : this.warehouses.find((warehouse) => warehouse.id === warehouseId)?.serviceId;
      switch (serviceId) {
        case 0:
          return require("@/assets/smaregi-logo.svg");
        case 1:
          return require("@/assets/shopify.svg");
        case 100:
          return require("@/assets/futureshop.svg");
        case 101:
          return require("@/assets/shopify.svg");
        case 103:
          return require("@/assets/base.svg");
        case 200:
          return require("@/assets/logizard.png");
        default:
          return require("@/assets/customServiceLogo.svg");
      }
    },
    getChanelName(storeId: string | null, warehouseId: string | null): string {
      const chanelName =
        warehouseId === null
          ? this.stores.find((store) => store.id === storeId)?.name
          : this.warehouses.find((warehouse) => warehouse.id === warehouseId)?.name;
      return chanelName ?? "";
    },
    optionSelected(event: any) {
      if (event === "SKU削除") {
        this.showAlert("", `本当に選択したSKUデータ${this.selectedItems.length}件を削除してよろしいですか？`, "error");
      } else if (event === "アクション作成") {
        this.showingCreateActionDialog = true;
      } else if (event === "アクションへ追加") {
        this.showingAddSkuIntoActionDialog = true;
      }
    },
    cancelCreateAction() {
      // 設定をリセット
      this.showingCreateActionDialog = false;
      this.inputCustomActionName = "";
      this.addActionListInfo.title = null;
      this.addActionListInfo.offRatio = null;
      this.addActionListInfo.managerId = null;
      this.addActionListInfo.executedDate = null;
      this.createActionDialogPageNumber = 0;
    },
    cancelAddSkuIntoAction() {
      // 設定をリセット
      this.showingAddSkuIntoActionDialog = false;
      this.inputActionName = "";
      this.selectedAction = null;
      this.addProductIntoActionDialogPageNumber = 0;
    },
    async addSkuIntoAction() {
      if (this.selectedAction) {
        const skuIds: string[] = this.selectedItems
          .map((item) => {
            if (!item.actions.map((action) => action.actionId).includes(this.selectedAction?.id ?? ""))
              return item.skuId;
          })
          .filter((item): item is NonNullable<typeof item> => item != null);
        const dto: SkuListActionAddSkusDto = {
          actionId: this.selectedAction.id,
          skuIds,
        };
        await RadialApiClient.addActionToSkus(dto);
        this.createdActionId = dto.actionId;
        // テーブルの情報を再取得
        await this.search();

        // アクションリストを新しいタブで開くかのダイアログ
        switch (this.selectedActionType) {
          case "CUSTOM":
            this.showAlert(
              "",
              `${this.selectedAction.title}に商品を追加しました！
            詳細をアクションリストで閲覧しますか？`,
              "success"
            );
            break;
          case "INTERSTORETRANSFER":
            this.showAlert(
              "",
              `店間移動のアクションリストを作成中です
              (この処理には時間がかかることがあります)`,
              "success"
            );
            break;
        }
        // 設定をリセット
        this.showingAddSkuIntoActionDialog = false;
        this.inputActionName = "";
        this.selectedAction = null;
        this.addProductIntoActionDialogPageNumber = 0;
        this.selectedItems = [];
        this.showingAddSkuIntoActionDialog = false;
        this.isLoading = false;
      } else {
        this.showAlert("", "選択されているアクションがありません", "error");
      }
    },
    async createAction() {
      this.isLoading = true;
      // Actionテーブルにレコードを追加
      const actionDto: CreateActionDto = {
        title: this.addActionListInfo.title ?? "",
        type: this.selectedActionType,
        customActionMasterId: this.selectedCustomActionMaster ? this.selectedCustomActionMaster.id : null,
        creatorAdminUserId: this.user?.sub,
        managerAdminUserId: this.addActionListInfo.managerId,
        status: "PENDING",
        company: { connect: { id: store.state.company?.id } },
        executedDate: this.addActionListInfo.executedDate,
      };
      const action = await RadialApiClient.createAction(actionDto);

      if (action) {
        // アクションと商品を紐づける
        await RadialApiClient.addActionToSkus({
          actionId: action.id,
          skuIds: this.selectedItems.map((item) => item.skuId),
        });
        // テーブルの情報を再取得
        await this.search();

        // アクションリストを新しいタブで開くかのダイアログ
        switch (this.selectedActionType) {
          case "CUSTOM":
            this.createdActionId = action.id;
            this.showAlert(
              "",
              `${this.addActionListInfo.title}を作成しました！
              詳細をアクションリストで閲覧しますか？`,
              "success"
            );
            break;
          case "INTERSTORETRANSFER":
            this.createdActionId = action.id;
            this.showAlert(
              "",
              `店間移動のアクションリストを作成中です
              (この処理には時間がかかることがあります)`,
              "success"
            );
            break;
        }

        // 設定をリセット
        this.showingCreateActionDialog = false;
        this.inputCustomActionName = "";
        this.addActionListInfo.title = null;
        this.addActionListInfo.offRatio = null;
        this.addActionListInfo.managerId = null;
        this.addActionListInfo.executedDate = null;
        this.createActionDialogPageNumber = 0;
        this.selectedItems = [];
      } else {
        this.showAlert("", "エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }

      this.showingCreateActionDialog = false;
      this.isLoading = false;
    },
    async createCustomActionMaster() {
      this.isLoading = true;
      const customActionMaster = await RadialApiClient.createCustomActionMaster({
        name: this.inputCustomActionName,
        company: { connect: { id: this.user?.companyId } },
      });
      if (customActionMaster) {
        this.customActionMasters.push(customActionMaster);
        this.selectedCustomActionMaster = customActionMaster;
        this.inputCustomActionName = "";
      }
      this.isLoading = false;
    },
    openActionList(actionId: string | null = null) {
      if (actionId) {
        const routeData = this.$router.resolve({
          name: "AdminActionList",
          params: { actionId },
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "AdminActionList",
          params: this.createdActionId ? { actionId: this.createdActionId } : undefined,
        });
        window.open(routeData.href, "_blank");
        this.resetActionInfo();
      }
    },
    resetActionInfo() {
      // 設定をリセット
      this.inputCustomActionName = "";
      this.selectedCustomActionMaster = null;
      this.addActionListInfo.title = null;
      this.addActionListInfo.offRatio = null;
      this.addActionListInfo.managerId = null;
      this.addActionListInfo.executedDate = null;
      this.createActionDialogPageNumber = 0;
      this.showingAlert = false;
    },
    async deleteSku() {
      this.isLoading = true;
      this.isDeleting = true;
      this.showingAlert = false;
      const promises: Promise<Sku | null>[] = [];
      for (const item of this.selectedItems) {
        promises.push(RadialApiClient.deleteSku(item.skuId));
        await Promise.all(promises);
        this.selectedItems = [];
      }
      await this.searchButtonDidClick();
      this.isDeleting = false;
      this.isLoading = false;
    },
    selectSkuAction(skuId: string) {
      this.requestSkuId = skuId;
      this.showingRequestActionDialog = true;
    },
    selectSkuRequests(requests: SkuRequestData[], type: ActionType) {
      this.selectedSkuRequests = [];
      this.selectedActionRequestType = type;
      this.skuRequests = requests.filter((request) => request.type === type);
    },
    async deleteSkuRequest(request: SkuRequestData, skuId: string) {
      const deletedRequest = await RadialApiClient.deleteStockActionDetail(request.stockActionDetailId);
      if (deletedRequest) {
        const updatedRequests = this.skuRequests.filter(
          (item) => item.stockActionDetailId !== request.stockActionDetailId
        );
        this.skuListDatas.filter((item) => item.skuId === skuId)[0].requests = updatedRequests;
        this.skuRequests = updatedRequests;
        this.selectedSkuRequests = this.selectedSkuRequests.filter(
          (item) => item.stockActionDetailId !== request.stockActionDetailId
        );
      } else {
        this.showAlert("", "エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
    },
    addRequestButtonDisable(): boolean {
      if (this.addRequestInfo.sourceId === this.addRequestInfo.destinationId) {
        return true;
      } else {
        return !(this.addRequestInfo.sourceId && this.addRequestInfo.destinationId && this.addRequestInfo.quantity);
      }
    },
    cancelRequest() {
      // 設定をリセット
      this.showingRequestActionDialog = false;
      this.selectedActionRequestType = "STOCKMOVING";
      this.addRequestInfo.sourceId = null;
      this.addRequestInfo.destinationId = null;
      this.addRequestInfo.quantity = null;
      this.createRequestDialogPageNumber = 0;
      this.requestSkuId = null;
    },
    selectActionRequestType(actionType: ActionType) {
      this.selectedActionRequestType = actionType;
    },
    async createRequest() {
      this.isLoading = true;
      // TODO: エラーハンドリング追加（無理な在庫移動をリクエストしている場合。バックエンド側対応）
      const actionDto: CreateStockActionDetailDto = {
        creatorAdminUserId:
          this.user?.adminUserName === "staff" || this.user?.adminUserName === "root" ? null : this.user?.sub,
        creatorStoreId: this.user?.adminUserName === "staff" ? this.user?.sub : null,
        sourceStoreId: this.selectedActionRequestType === "STOCKMOVING" ? this.addRequestInfo.sourceId : null,
        sourceWarehouseId: this.selectedActionRequestType === "STOCKFOLLOW" ? this.addRequestInfo.sourceId : null,
        // 倉庫返却などの今後のアップデートタイミングで更新
        destinationWarehouseId: null,
        destinationStoreId: this.addRequestInfo.destinationId,
        quantity: Number(this.addRequestInfo.quantity) ?? 0,
        sku: { connect: { id: this.requestSkuId ?? "" } },
        company: { connect: { id: this.user?.companyId } },
      };
      const request = await RadialApiClient.createStockActionDetail(actionDto);
      if (request) {
        await this.search();
        if (this.selectedSku) {
          // 詳細ビューを更新
          this.selectedSku = this.skuListDatas.find((item) => item.skuId === this.selectedSku?.skuId) ?? null;
        }
        // 設定をリセット
        this.showingRequestActionDialog = false;
        this.selectedActionRequestType = "STOCKMOVING";
        this.addRequestInfo.sourceId = null;
        this.addRequestInfo.destinationId = null;
        this.addRequestInfo.quantity = null;
        this.createRequestDialogPageNumber = 0;
        this.requestSkuId = null;
      } else {
        this.showAlert("", "エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
      this.isLoading = false;
    },
    async addRequestIntoAction() {
      const response = await RadialApiClient.createActionFromRequests({
        stockActionDetailIds: this.selectedSkuRequests.map((item) => {
          return item.stockActionDetailId;
        }),
        // TODO: このtitleはそもそもAPIの設計として不必要。バックエンド修正
        title: this.selectedAction?.title ?? "",
        actionId: this.selectedAction?.id ?? "",
      });
      if (response) {
        await this.search();
        if (this.selectedSku) {
          // 詳細ビューを更新
          this.selectedSku = this.skuListDatas.find((item) => item.skuId === this.selectedSku?.skuId) ?? null;
        }
        this.showingAddRequestIntoActionDialog = false;
        this.selectedActionRequestType = "STOCKMOVING";
        this.inputActionName = "";
        this.selectedAction = null;
      } else {
        this.showAlert("", "エラーが発生しております。\n時間を空けてからもう一度お試しください。", "error");
      }
    },
    cancelRequestIntoAction() {
      // 設定をリセット
      this.showingAddRequestIntoActionDialog = false;
      this.selectedActionRequestType = "STOCKMOVING";
      this.inputActionName = "";
      this.selectedAction = null;
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.user) {
      if (this.isRoot) {
        this.companyAdminUser = await RadialApiClient.getCompany(this.user.sub);
        if (this.companyAdminUser) {
          this.adminUserDetails.push({
            id: this.companyAdminUser.id,
            icon: this.companyAdminUser.icon ?? "",
            name: this.companyAdminUser.name,
          });
        }
      } else {
        this.companyAdminUser = await RadialApiClient.getAdminUser(this.user.sub);
      }
    }
    const searchString = this.$route.query.search as string;
    if (searchString) {
      this.searchEntries = JSON.parse(searchString);
    }
    const rangeDatesString = this.$route.query.rangeDates as string;
    if (rangeDatesString) {
      this.rangeDates = JSON.parse(rangeDatesString);
    }
    await RadialApiClient.listAdminUsers().then((adminUsers) => {
      (adminUsers ?? [])
        .filter((adminUser) => adminUser.adminUserMasterId !== 3) // CSロールは除く
        .map((adminUser) => {
          this.adminUserDetails.push({ id: adminUser.id, icon: adminUser.icon ?? "", name: adminUser.name });
        });
    });
    // custom action masterのテーブルを取得
    this.customActionMasters = (await RadialApiClient.listCustomActionMasters()) ?? [];
    const skuFilterInfo = await RadialApiClient.getSkuFilterInfo();
    if (skuFilterInfo) {
      this.skuFilterInfo = skuFilterInfo;
      this.skuFilterInfo.brands.sort((a, b) => {
        if (a.code && b.code) {
          if (a.code > b.code) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (a.name > b.name) {
            return 1;
          } else {
            return -1;
          }
        }
      });
      const seasons = await RadialApiClient.listSeasons();
      if (seasons) {
        this.skuFilterInfo.seasons.sort((a, b) => {
          const seasonA = seasons.find((season) => season.name === a.name);
          const seasonB = seasons.find((season) => season.name === b.name);
          if (seasonA && seasonB) {
            if (seasonA.startYearMonth < seasonB.startYearMonth) {
              return 1;
            } else if (seasonA.startYearMonth === seasonB.startYearMonth) {
              if (seasonA.code && seasonB.code) {
                return seasonA.code > seasonB.code ? 1 : -1;
              } else if (seasonA.code && seasonB.code === "") {
                return -1;
              } else if (seasonA.code === "" && seasonB.code) {
                return 1;
              } else {
                return seasonA.name > seasonB.name ? -1 : 1;
              }
            } else {
              return -1;
            }
          } else {
            return 1;
          }
        });
      }
      this.skuFilterInfo.categories.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code) {
          return -1;
        } else if (b.code) {
          return 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });
      this.skuFilterInfo.attribute1.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code) {
          return -1;
        } else if (b.code) {
          return 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });
      this.skuFilterInfo.attribute2.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code) {
          return -1;
        } else if (b.code) {
          return 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });
      this.skuFilterInfo.attribute3.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code) {
          return -1;
        } else if (b.code) {
          return 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });
      this.skuFilterInfo.colors.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code) {
          return -1;
        } else if (b.code) {
          return 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });
      this.skuFilterInfo.sizes.sort((a, b) => {
        // 属性コードがない場合は名前でソート
        if (a.code && b.code) {
          return a.code > b.code ? 1 : -1;
        } else if (a.code) {
          return -1;
        } else if (b.code) {
          return 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      });
    }
    await Promise.all([
      (async () => {
        this.alertMasters = (await RadialApiClient.listAlertMasters()) ?? [];
      })(),
      (async () => {
        this.stores = (await RadialApiClient.listStores()) ?? [];
        this.stores.map((store) => {
          if (store.operating) {
            this.productFilterStoreInput.push({ id: store.id, name: store.name, code: null });
          }
        });
      })(),
      (async () => {
        this.warehouses = (await RadialApiClient.listWarehouses()) ?? [];
      })(),
      (async () => {
        this.actions = (await RadialApiClient.listAllActions()) ?? [];
      })(),
    ]);
    await this.search();
    this.isLoading = false;
  },
});
