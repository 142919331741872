export class Action {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  title: string;
  type: ActionType;
  customActionMasterId: string | null;
  creatorAdminUserId: string | null;
  managerAdminUserId: string | null;
  executedDate: Date | null;
  status: ActionStatus;
  isEnabled: boolean;
}

export class AdminUserMaster {
  id: number;
  name: string;
  adminUsers?: AdminUser[];
}

export class AdminUser {
  id: string;
  email: string;
  name: string;
  itemSkuProgress: number;
  saleStockProgress: number;
  createdAt: Date;
  updatedAt: Date;
  imageProgress: number;
  adminUserMasterId: number | null;
  companyId: string;
  company?: Company;
  adminUserMaster?: AdminUserMaster | null;
  icon: string | null;
}

export class AlertMaster {
  id: number;
  name: string;
  storeIntegration: boolean;
  description: string;
  comment: string;
  prominenceColor: string;
  alerts?: Alert[];
  alertSettings?: AlertSetting[];
  individualAlertSetting?: IndividualAlertSetting[];
}

export class AlertSetting {
  id: string;
  companyId: string;
  alertMasterId: number;
  isEnabled: boolean;
  numbers: string;
  createdAt: Date;
  updatedAt: Date;
  excludedStoreIds: string;
  alertMaster?: AlertMaster;
}
export class Alert {
  id: string;
  companyId: string;
  productId: string;
  skuId: string | null;
  alertMasterId: number;
  userDeleted: boolean;
  isUpdated: boolean;
  deletedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}
export class Attribute {
  id: string;
  companyId: string;
  number: number;
  name: string;
  code: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export class BaseIntegration {
  id: number;
  companyId: string;
  refreshToken: string;
  janCode: BaseJanCodeRecognitionKey;
  stockSynchronized: boolean;
  lastBatchStartedAt: Date | null;
  lastBatchEndedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  company?: Company;
}
export class BatchResumeStatus {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  service: string;
  phase: string;
  start: Date;
  end: Date;
}

export class BrandWithCategoryInfoEntity {
  brand: Brand;
  categories: (Category & { index: number; brandCategoryId: string })[];
}
export class BrandCategory {
  id: string;
  companyId: string;
  brandId: number;
  categoryId: number;
  deleted: boolean;
  index: number;
  createdAt: Date;
  updatedAt: Date;
}

export class Brand {
  id: number;
  companyId: string;
  name: string;
  code: string | null;
  createdAt: Date;
  updatedAt: Date;
  brandCategories?: BrandCategory[];
}
export class BudgetSeasonRate {
  id: string;
  companyId: string;
  seasonId: number;
  year: number;
  month: number;
  rate: number;
  createdAt: Date;
  updatedAt: Date;
}
export class BudgetWeekRate {
  id: string;
  companyId: string;
  year: number;
  month: number;
  weekday: number;
  weekend: number;
  createdAt: Date;
  updatedAt: Date;
}

export class Category {
  id: number;
  companyId: string;
  name: string;
  code: string | null;
  createdAt: Date;
  updatedAt: Date;
  brandCategories?: BrandCategory[];
}
export class Color {
  id: string;
  companyId: string;
  name: string | null;
  code: string;
  createdAt: Date;
  updatedAt: Date;
}
export class Comment {
  id: string;
  companyId: string;
  productId: string;
  skuId: string | null;
  memberId: string | null;
  storeId: string | null;
  age: string;
  figure: string;
  height: string;
  content: string;
  gender: string;
  positivity: number;
  servedAt: Date;
  target: number;
  category: number;
  createdAt: Date;
  updatedAt: Date;
}

export class Company {
  id: string;
  email: string;
  name: string;
  address: string | null;
  itemSkuProgress: number;
  saleStockProgress: number;
  createdAt: Date;
  updatedAt: Date;
  imageProgress: number;
  icon: string | null;
  maxAdminNumber: number;
  maxStoreNumber: number;
  phoneNumber: string | null;
  postalCode: string | null;
  plan: CompanyPlan;
  alertPlan: AlertPlan;
  baseIntegration?: BaseIntegration | null;
  customIntegrations?: CustomIntegration[];
  futureshopIntegration?: FutureshopIntegration | null;
  logizardIntegration?: LogizardIntegration | null;
  shopifyIntegration?: ShopifyIntegration | null;
  smaregiIntegration?: SmaregiIntegration | null;
  makeshopIntegration?: MakeshopIntegration | null;
  nextEngineIntegration?: NextEngineIntegration | null;
  stores?: Store[];
  warehouses?: Warehouse[];
}

export class CustomActionDetail {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  actionId: string | null;
  productId: string;
  skuId: string | null;
  action?: Action | null;
}
export class CustomActionMaster {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  name: string;
}
export class CustomIntegration {
  id: string;
  companyId: string;
  serviceId: number;
  productBargain: number;
  headCouponBargain: number;
  headPointBargain: number;
  headBundleBargain: number;
  headRoundingBargain: number;
  createdAt: Date;
  updatedAt: Date;
}

export class CustomTagMaster {
  id: number;
  name: string;
  companyId: string;
  createdAt: Date;
  updatedAt: Date;
  customTags?: CustomTag[];
}

export class CustomTag {
  id: number;
  customTagMasterId: number;
  productId: string;
  companyId: string;
  creatorAdminUserId: string | null;
  createdAt: Date;
  updatedAt: Date;
  customTagMaster?: CustomTagMaster;
}
export class Factory {
  id: string;
  name: string;
  companyId: string;
  address: string;
  phoneNumber: string;
  code: string;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export class FutureshopIntegration {
  id: number;
  shopKey: string;
  clientId: string;
  clientSecret: string;
  janCode: FutureshopJanCodeRecognitionKey;
  companyId: string;
  headProductCouponDiscountTotal: number;
  headVolumeDiscountTotal: number;
  lastBatchStartedAt: Date | null;
  lastBatchEndedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  stockSynchronized: boolean;
  company?: Company;
}

export class IndividualAlertSetting {
  id: string;
  companyId: string;
  alertMasterId: number;
  numbers: string;
  productId: string;
  createdAt: Date;
  updatedAt: Date;
  excludedStoreIds: string;
  alertMaster?: AlertMaster;
}

export class InterstoreTransferActionDetail {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  actionId: string | null;
  skuId: string | null;
  sourceStoreId: string;
  targetStoreId: string;
  stockAmount: number;
  action?: Action | null;
  sourceStore?: Store;
  targetStore?: Store;
}

export class InterstoreTransferGroup {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  stores?: Store[];
  name: string;
}
export class ItemNumberLatest {
  id: number;
  companyId: string;
  seasonCode: string;
  brandCode: string;
  categoryCode: string;
  attribute1Code: string;
  attribute2Code: string;
  attribute3Code: string;
  latest: number;
  createdAt: Date;
  updatedAt: Date;
}
export class ItemNumberLogic {
  id: number;
  companyId: string;
  isEnabled: boolean;
  format: string;
  createdAt: Date;
  updatedAt: Date;
}
export class JanCodeLogic {
  id: number;
  companyId: string;
  prefix: number;
  latest: number;
  format: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export class LogizardIntegration {
  id: number;
  userId: string;
  groupId: string;
  connectionId: string;
  ownerId: string;
  areaId: string;
  authKey: string;
  itemId: LogizardJanCodeRecognitionKey;
  key1: LogizardJanCodeRecognitionKey | null;
  key2: LogizardJanCodeRecognitionKey | null;
  companyId: string;
  lastBatchStartedAt: Date | null;
  lastBatchEndedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  company?: Company;
}

export class MakeshopIntegration {
  id: number;
  companyId: string;
  shopId: string;
  productToken: string;
  orderToken: string;
  janCode: MakeshopJanCodeRecognitionKey;
  stockSynchronized: boolean;
  lastBatchStartedAt: Date | null;
  lastBatchEndedAt: Date | null;
}
export class Member {
  id: string;
  companyId: string;
  storeId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export class NextEngineIntegration {
  id: number;
  companyId: string;
  accessToken: string;
  refreshToken: string;
  janCode: NextEngineJanCodeRecognitionKey;
  stockSynchronized: boolean;
  lastBatchStartedAt: Date | null;
  lastBatchEndedAt: Date | null;
}
export class OrderInstructionDetail {
  id: string;
  orderSkuId: number | null;
  orderProductId: number | null;
  orderInstructionId: string;
  companyId: string;
  createdAt: Date;
  updatedAt: Date;
}

export class OrderInstruction {
  id: string;
  orderNumber: string;
  factoryId: string | null;
  companyId: string;
  orderedDate: Date;
  stockedDate: Date;
  status: OrderInstructionStatus;
  warehouseId: string | null;
  title: string | null;
  creatorAdminUserId: string | null;
  managerAdminUserId: string | null;
  createdAt: Date;
  updatedAt: Date;
  warehouse?: Warehouse | null;
  factory?: Factory | null;
}

export class OrderProduct {
  id: number;
  companyId: string;
  seasonId: number;
  brandId: number;
  categoryId: number;
  brandCategoryId: string | null;
  productId: string;
  year: number;
  month: number;
  stockedDate: Date;
  orderedDate: Date | null;
  stockedStatus: OrderProductStockedStatus;
  createdAt: Date;
  updatedAt: Date;
}
export class OrderSku {
  id: number;
  companyId: string;
  seasonId: number;
  brandId: number;
  categoryId: number;
  brandCategoryId: string | null;
  productId: string;
  skuId: string;
  orderProductId: number;
  orderQuantity: number;
  orderCost: number;
  createdAt: Date;
  updatedAt: Date;
}

export class ProductCustomValueSetting {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  name: string;
  type: CustomValueType;
}
export class ProductCustomValue {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  productId: string;
  value: string;
  productCustomValueSettingId: string;
}
export class ProductImage {
  id: string;
  companyId: string;
  productId: string;
  colorId: string | null;
  index: number;
  fileName: string;
  createdAt: Date;
  updatedAt: Date;
}

export class Product {
  id: string;
  companyId: string;
  name: string | null;
  itemNumber: string | null;
  brandId: number | null;
  seasonId: number | null;
  gender: number | null;
  factoryId: string | null;
  store: ProductStore | null;
  categoryId: number | null;
  brandCategoryId: string | null;
  attribute1Id: string | null;
  attribute2Id: string | null;
  attribute3Id: string | null;
  price: number | null;
  salesPrice: number | null;
  salesStartedAt: Date | null;
  salesEndedAt: Date | null;
  salesPriceUpdatedAt: Date | null;
  description: string | null;
  composition: string | null;
  freeComment: string | null;
  directionMemo: string | null;
  leadTime: number | null;
  minimumOrderQuantity: number | null;
  status: ProductStatus;
  shopifyProductId: string | null;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
  skus?: Sku[];
}
export class ResultRate {
  id: string;
  companyId: string;
  seasonId: number;
  brandId: number;
  categoryId: number;
  brandCategoryId: string;
  storeId: string;
  year: number;
  month: number;
  weekNumber: number;
  saleRate: number;
  createdAt: Date;
  updatedAt: Date;
}

export class SaleActionDetail {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  actionId: string | null;
  productId: string;
  oldSalesPrice: number;
  newSalesPrice: number;
  action?: Action | null;
}
export class SaleAnalysis {
  id: string;
  companyId: string;
  seasonId: number;
  brandId: number;
  categoryId: number;
  brandCategoryId: string | null;
  storeId: string;
  year: number;
  month: number;
  day: number;
  saleResult: number;
  saleBudget: number;
  bargainSaleResult: number;
  bargainSaleBudget: number;
  properSaleResult: number;
  properSaleBudget: number;
  grossMarginResult: number;
  grossMarginBudget: number;
  createdAt: Date;
  updatedAt: Date;
}
export class SeasonBrandCategoryPlan {
  id: number;
  companyId: string;
  seasonId: number;
  brandId: number;
  categoryId: number;
  brandCategoryId: string | null;
  year: number;
  month: number;
  saleRate: number;
  buyingRate: number;
  openingStock: number;
  averagePriceBudget: number;
  averageOrderQuantityBudget: number;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}
export class SeasonBrandCategory {
  id: number;
  companyId: string;
  seasonId: number;
  brandId: number;
  brandCategoryId: string | null;
  categoryId: number;
  digestRateTarget: number;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}
export class SeasonPlan {
  id: number;
  companyId: string;
  seasonId: number;
  year: number;
  month: number;
  properRateTarget: number;
  offRateTarget: number;
  directionMemo: string | null;
  createdAt: Date;
  updatedAt: Date;
}
export class SeasonDeleteInfoEntity {
  product: number;
  sku: number;
  order: number;
  comment: number;
}
export class Season {
  id: number;
  companyId: string;
  name: string;
  startYearMonth: Date;
  endYearMonth: Date;
  refSeasonId: number | null;
  createdAt: Date;
  updatedAt: Date;
  costRateTarget: number;
  code: string | null;
}

export class Service {
  id: number;
  name: string;
  type: ServiceType;
  manual: boolean;
}

export class ShopifyCustomization {
  id: number;
  companyId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string | null;
  radialConnection: ShopifyRadialConnection | null;
  radialConnectionNew: RadialConnection | null;
  company?: Company;
}

export class ShopifyIntegration {
  id: number;
  companyId: string;
  shopName: string;
  accessToken: string;
  posEcStatus: number;
  lastBatchStartedAt: Date | null;
  lastBatchEndedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  posHeadDiscountPrice: number;
  posProductUnitDiscountPrice: number;
  ecHeadDiscountPrice: number;
  ecProductUnitDiscountPrice: number;
  janCodeRecognition: ShopifyJanCodeRecognitionKey;
  productSynchronized: boolean;
  company?: Company;
}

export class ShopifyOption {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  name: ShopifyOptionType;
  index: number | null;
  radialConnection: RadialConnection | null;
  free: string | null;
  company?: Company;
}
export class Size {
  id: string;
  companyId: string;
  name: string | null;
  code: string;
  createdAt: Date;
  updatedAt: Date;
}

export class SkuCustomValueSetting {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  name: string;
  type: CustomValueType;
}
export class SkuCustomValue {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  skuId: string;
  value: string;
  skuCustomValueSettingId: string;
}
export class Sku {
  id: string;
  companyId: string;
  productId: string;
  janCode: string | null;
  colorId: string;
  sizeId: string;
  cost: number | null;
  smaregiProductId: string | null;
  shopifyVariantId: string | null;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
}

export class SmaregiIntegration {
  id: number;
  companyId: string;
  contractId: string;
  productUnitDiscountPrice: number;
  productApplyBargainDiscountPrice: number;
  headDiscountPriceProportional: number;
  headDiscountCouponProportional: number;
  headProductBundleProportional: number;
  headRoundingPriceProportional: number;
  category: SmaregiCategory | null;
  unitPrice: SmaregiUnitPrice | null;
  productNumber: SmaregiProductNumber | null;
  lastBatchStartedAt: Date | null;
  lastBatchEndedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  storeSynchronized: boolean;
  warehouseSynchronized: boolean;
  productSynchronized: boolean;
  company?: Company;
}

export class SmaregiOption {
  id: string;
  companyId: string;
  name: SmaregiOptionType;
  radialConnection: RadialConnection | null;
  reserveItemsCode: string | null;
  free: string | null;
  createdAt: Date;
  updatedAt: Date;
  company?: Company;
}
export class StockActionDetail {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  creatorAdminUserId: string | null;
  creatorStoreId: string | null;
  skuId: string | null;
  sourceStoreId: string | null;
  sourceWarehouseId: string | null;
  destinationWarehouseId: string | null;
  destinationStoreId: string | null;
  quantity: number;
  actionId: string | null;
}
export class Stock {
  id: string;
  companyId: string;
  warehouseId: string | null;
  itemId: string;
  key1: string;
  key2: string;
  quantity: number;
  serviceId: number;
  posId: string;
  skuId: string | null;
  storeId: string | null;
  createdAt: Date;
  updatedAt: Date;
}
export class StoreAnalysisUpdated {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  companyId: string;
  storeId: string;
  day: string;
}
export class StoreAnalysis {
  id: string;
  companyId: string;
  storeId: string;
  status: number;
  priceAmount: number;
  purchasersCount: number;
  quantityAmount: number;
  customersCount: number;
  weather: number | null;
  maxTemperature: number | null;
  averageTemperature: number | null;
  minTemperature: number | null;
  memberId: string | null;
  description: string | null;
  year: number;
  month: number;
  day: number;
  createdAt: Date;
  updatedAt: Date;
}
export class StoreDailyPlan {
  id: string;
  companyId: string;
  storeId: string;
  year: number;
  month: number;
  day: number;
  saleBudget: number;
  createdAt: Date;
  updatedAt: Date;
}
export class StoreMonthlyPlan {
  id: string;
  companyId: string;
  storeId: string;
  year: number;
  month: number;
  saleBudget: number;
  createdAt: Date;
  updatedAt: Date;
}

export class Store {
  id: string;
  companyId: string;
  cognitoSub: string | null;
  serviceId: number | null;
  posId: string | null;
  name: string;
  email: string | null;
  postalCode: string | null;
  interstoreTransferGroupId: string | null;
  createdAt: Date;
  updatedAt: Date;
  deleted: boolean;
  icon: string | null;
  operating: boolean;
  shareRate: number;
  members?: Member[];
}
export class Transaction {
  id: string;
  companyId: string;
  posId: string;
  posHeadId: string;
  posDetailId: string;
  completedAt: Date;
  janCode: string;
  serviceId: number;
  price: number | null;
  salesPrice: number;
  discountPrice: number;
  discountPriceProportional: number;
  totalHead: number;
  quantity: number;
  nonDiscountSubtotal: number;
  discountSubtotal: number;
  shareRatedDiscountSubtotal: number;
  isSale: boolean;
  storeId: string | null;
  createdAt: Date;
  updatedAt: Date;
}

export class Warehouse {
  id: string;
  companyId: string;
  serviceId: number | null;
  name: string;
  posId: string;
  isIntegrated: boolean;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  stocks?: Stock[];
  orderInstructions?: OrderInstruction[];
}
export class BaseTokenIO {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
}

export class BaseOrderIO {
  unique_key: string;
  ordered: number;
  cancelled: number;
  dispatched: string;
  payment: string;
  first_name: string;
  last_name: string;
  total: number;
  delivery_date: number;
  delivery_time_zone: number;
  terminated: boolean;
  dispatch_status: string;
  modified: number;
  subscription: {
    unique_key: string;
    repeat_number: number;
    repeat_times: number;
  };
}

//ver4.14使用項目のみ抜粋
export class BaseOrderDetailIO {
  unique_key: string;
  ordered: number;
  total: number;
  dispatch_status: string;
  order_items: BaseOrderDetailOrderItemIO[];
}
export class BaseOrderDetailOrderItemIO {
  variation_identifier: string;
  barcode: string;
  price: number;
  amount: number;
}

//ver4.14使用項目のみ抜粋
export class BaseItemIO {
  price: number;
  proper_price: number;
  modified: number;
  variations: BaseItemVariationIO[];
}

export class BaseItemVariationIO {
  variation_stock: number;
  variation_identifier: string;
  barcode: string;
}

export class BaseOrderIOParams {
  start_ordered?: string;
  end_ordered?: string;
  limit?: number;
  offset?: number;
}

//ver4.14使用項目のみ抜粋
export class BaseItemIOParams {
  order: 'modified';
  sort: 'desc';
  limit?: number;
  offset?: number;
}

export class BaseErrorIO {
  error: string;
  error_description: string;
}

export class ActionListSearchResultEntity {
  actionId: string;
  title: string;
  type: ActionType;
  customActionMasterId: string | null;
  creatorAdminUserId: string | null;
  managerAdminUserId: string | null;
  status: ActionStatus;
  createdAt: Date;
  executedDate: Date | null;
  isEnabled: boolean;
}

export class ActionListProductSaleActionDetailEntity {
  actionDetailId: string;
  product: Product;
  oldSalesPrice: number;
  newSalesPrice: number;
}

export class ActionListSkuDetailStockActionEntity {
  stockActionDetailId: string;
  stockActionType: ActionType; // ActionType = "SALE" | "CUSTOM" | "STOCKMOVING" | "STOCKFOLLOW"
  itemNumber: string;
  productName: string;
  sku: Sku;
  sourceStoreId: string | null; // 移動元が倉庫の場合null
  sourceWarehouseId: string | null; // 移動元が店舗の場合null
  destinationStoreId: string; // 5.3.2時点では移動先が店舗のみのためStoreIdのみ
  quantity: number;
}

export class ActionListInterstoreTransferActionDetailEntity {
  id: string;
  sku: Sku;
  sourceStore: Store | null;
  targetStore: Store | null;
  sourceStoreStock: Stock | null;
  targetStoreStock: Stock | null;
  stockAmount: number;
  itemNumber: string;
  productName: string;
}

export class ActionListCustomActionEntity {
  actionDetailId: string; // customActionDetailIdが入っている
  skuId: string | null;
  productId: string;
  itemNumber: string;
  janCode: string | null;
  productName: string;
  colorName: string | null;
  sizeName: string | null;
  seasonName: string;
  brandName: string;
  categoryName: string;
  salesPrice: number;
  saleStartedAt: Date;
  saleEndedAt: Date;
}

export class ActionRequestSearchResultEntity {
  stockActionDetailId: string;
  s3Key: string;
  productId: string;
  itemNumber: string;
  productName: string;
  sku: Sku;
  sourceStoreId: string | null; // 移動元が倉庫の場合null
  sourceWarehouseId: string | null; // 移動元が店舗の場合null
  destinationStoreId: string; // 5.3.2時点では移動先が店舗のみのためStoreIdのみ
  sourceStock: number; // 移動元の在庫数
  destinationStock: number; // 移動先の在庫数
  quantity: number;
  createdAt: Date;
  creatorAdminUserId: string | null;
  creatorStoreId: string | null;
}
export class CsvCheckResultEntity {
  errors: CsvCheckErrorEntity[]; // エラーがないとき空配列
  status: number; // 0: 成功, 1: 入力内容不正, 2: フォーマット不正(全然違うなど)
  createCount?: number;
  updateCount?: number;
  incompleteCount?: number;
}

export class CsvItemSkuCheckResultEntity {
  csvType: CsvProductSku;
  errors: CsvCheckErrorEntity[]; // エラーがないとき空配列
  status: number; // 0: 成功, 1: 入力内容不正, 2: フォーマット不正(全然違うなど)
  createCount?: number;
  updateCount?: number;
}

export enum CsvProductSku {
  PRODUCT = 'PRODUCT',
  SKU = 'SKU',
  OTHER = 'OTHER'
}

export class CsvCheckErrorEntity {
  message: string; // "salesStartedAtは「yyyy-MM-dd」のフォーマットで入力してください"など
  rows: number[][]; //重複エラーの場合はエラー分配列が複数、 行単位のエラーは配列の0番目にエラー行を格納
}

export class CsvUploadResultEntity {
  message: string;
}

export class CsvProductNumbersEntity {
  productNumber: number; // 品番数
  skuNumber: number; // sku数
}

export class SkuCsvDataEntity {
  entities: SkuCsvEntity[];
  customColumnHeaders: string[];
}
export class SkuCsvEntity {
  itemNumber: string;
  janCode: string;
  colorCode: string | null;
  sizeCode: string | null;
  cost: number | null;
  customColumns: CustomColumnEntity[];
}

export class ProductCsvDataEntity {
  entities: ProductCsvEntity[];
  customColumnHeaders: string[];
}

export class ProductCsvEntity {
  itemNumber: string;
  status: number;
  name: string | null;
  brand: string;
  season: string;
  category: string;
  gender: number | null;
  factory: string | null;
  store: number | null;
  attribute1: string | null;
  attribute2: string | null;
  attribute3: string | null;
  salesStartedAt: string | null;
  salesEndedAt: string | null;
  price: number;
  salesPrice: number | null;
  salesPriceUpdatedAt: string | null;
  composition: string | null;
  description: string | null;
  freeComment: string | null;
  directionMemo: string | null;
  leadTime: number | null;
  minimumOrderQuantity: number | null;
  customColumns: CustomColumnEntity[];
}

export class SmaregiSkuCsvDataEntity {
  entities: SmaregiSkuCsvEntity[];
  customColumnHeaders: string[];
}

export class SmaregiSkuCsvEntity {
  janCode: string | null; // 商品コード
  productNumberId: string | null; // 商品ID
  smaregiIntegratedCategoryCode: string | null; // 部門ID
  name: string | null; // 商品名
  salesPrice: number; // 商品単価
  itemNumber?: string; // 品番
  appStartDate?: string; // 適用開始日
  color?: string; // カラー
  size?: string; // サイズ
  cost?: number; //原価
  description?: string; // 説明
  groupCode?: string; // グループコード
  purchaseCost?: number; // 仕入原価
  taxDivision?: string; // 税区分
  customColumns: CustomColumnEntity[];
}
export class ShopifySkuCsvDataEntity {
  entities: ShopifySkuCsvEntity[];
  customColumnHeaders: string[];
}
export class ShopifySkuCsvEntity {
  handle?: string;
  title: string;
  bodyHtml?: string;
  vendor?: string;
  tags?: string;
  option1name?: string;
  option1value?: string;
  option2name?: string;
  option2value?: string;
  option3name?: string;
  option3value?: string;
  productType?: string; // CSVのカラム名はType
  variantSku?: string;
  variantBarcode?: string;
  variantInventoryTracker?: string;
  variantPrice?: string;
  variantCompareAtPrice?: string;
  variantTaxable?: string;
  variantRequiresShipping?: string;
  customColumns: CustomColumnEntity[];
}

export class CustomColumnEntity {
  name: string;
  value: string | number;
}

export class CsvSaleNumbersEntity {
  saleNumber: number;
}

export class CsvStockNumbersEntity {
  stockNumber: number;
}

export class CsvSaleStockDownloadEntity {
  S3Key: string;
}
export class DailyStoreBudgetEntity {
  budgets: number[]; // 予算を配列(配列の長さは指定年月の日の長さ)
  monthBudget: number; // 月の予算
}
export class DashboardSalesEntity {
  name: string | null;
  posId: string;
  completedAt: Date;
  sumDiscountSubtotal: number;
  avgDiscountSubtotal: number;
  sumQuantity: number;
  countUniqueTransaction: number;
}

export class DashboardSalesByBrandEntity {
  brand: string | null;
  posId: string;
  sumDiscountSubtotal: number;
}

export class DashboardSalesByCategoryEntity {
  category: string | null;
  posId: string;
  sumDiscountSubtotal: number;
}

export class DashboardSalesByProductEntity {
  name: string | null;
  itemNumber: string | null;
  sumDiscountSubtotal: number;
  sumPositiveCommentsByProductId: number | null;
  sumNegativeCommentsByProductId: number | null;
  sumNeutralCommentsByProductId: number | null;
  content: string | null;
  productId: string | null;
}

export class DashboardCommentByStoreEntity {
  posId: string;
  name: string;
  countCommentsByStore: number;
}

export class DashboardCommentByMemberEntity {
  posId: string;
  memberName: string;
  storeName: string;
  countCommentsByMember: number;
}

export class DashboardProductRankingEntity {
  productId: string | null;
  name: string | null;
  itemNumber: string | null;
  sumDiscountSubtotal: number;
  sumPositiveCommentsByProductId: number | null;
  sumNegativeCommentsByProductId: number | null;
  sumNeutralCommentsByProductId: number | null;
}

export class DashboardSaleAnalysisItemEntity {
  date: string;
  saleResult: number;
  saleBudget: number;
  bargainSaleResult: number;
  bargainSaleBudget: number;
  properSaleResult: number;
  properSaleBudget: number;
  grossMarginResult: number;
  grossMarginBudget: number;
}

export class DashboardSaleAnalysisEntity {
  items: DashboardSaleAnalysisItemEntity[];
  total: DashboardSaleAnalysisItemEntity; // dateは"total"
}

export class DashboardSeasonEntity {
  seasonId: number;
  plannedBuyingRate: number;
  statusRate: number[]; // 割合が％で5個配列に入る
}

export class DeliveryMapStockCountEntity {
  color: string;
  count: number;
}

export class DeliveryMapProductEntity {
  product: Product;
  stockCounts: DeliveryMapStockCountEntity[];
  customTags: CustomTag[];
}

export class DeliveryMapEntity {
  brands: {
    brand: Brand;
    categories: { category: Category; products: DeliveryMapProductEntity[] }[];
  }[];
}
export class GroupListGraphItemEntity {
  id: string | number;
  name: string;
  dates: GroupListGraphItemDateEntity[];
  total: GroupListGraphItemTotalEntity;
}

export class GroupListGraphItemDateEntity {
  date: string;
  saleResultAmount: number;
  saleResultQuantity: number;
}

export class GroupListGraphItemTotalEntity {
  saleResultAmount: number;
  saleResultQuantity: number;
  digestionRateAll: number; //消化率 全体
  digestionRateProper: number; //消化率 プロパー
}
export class S3KeyProductEntity {
  productId: string;
  productImageId: string;
  s3Key: string; //メイン画像
}

export class GetMainImagesEntity {
  s3KeyProducts: S3KeyProductEntity[];
}

export class ImageCheckResultEntity {
  errors: ImageCheckErrorEntity[]; // エラーがないとき空配列
  status: number; // 0: 成功, 1: 入力内容不正, 2: フォーマット不正(全然違うなど)
  createCount?: number;
  updateCount?: number;
}

export class ImageCheckErrorEntity {
  message: string; // "画像ファイルではありません。拡張子をご確認いただき再度アップロードしてください。"など
  fileNames: string[];
}

export class GetDetailImagesEntity {
  s3KeySkus: S3KeySkuEntity[];
  s3KeyMain: string;
}

export class S3KeySkuEntity {
  skuId: string;
  s3KeyWithId: s3KeyWithId[];
}

export class s3KeyWithId {
  productImageId: string;
  s3Key: string;
}

export class S3KeySkuMainEntity {
  skuId: string;
  s3Key: string; //メイン画像(該当のFKU画像がない場合は空文字列を返す)
}
export class ItemNumberLatestEntity {
  latest: string;
}

export class MdMapStatsEntity {
  brand: Brand;
  category: Category;
  brandId: number; // TO BE REMOVED
  categoryId: number; // TO BE REMOVED
  brandCategoryId: string;
  seasonBrandCategoryPlanId: number;
  neededBuyingBudget: number; // 必要仕入予算
  // buyingResult: number; // 仕入実績
  buyingBudget: number; // 仕入計画済予算
  buyingBudgetRemaining: number; // 仕入計画残
  averagePriceResult: number; // 平均定価実績
  averagePriceBudget: number; // 平均定価予算
  neededBuyingQuantityBudget: number; // 必要仕入数予算
  // buyingQuantityResult: number; // 仕入数実績
  buyingQuantityBudget: number; // 仕入数計画済予算
  averageOrderQuantityResult: number; // 平均発注数実績
  averageOrderQuantityBudget: number; // 平均発注数予算
  itemQuantityResult: number; // 品番数実績
  itemQuantityBudget: number; // 品番数予算
  costResult: number; // 原価実績
  averageCostRate: number; // 平均原価率
}

export class MdMapStatsTotalEntity {
  buyingBudget: number; // 仕入計画済予算
  neededBuyingBudget: number; // 必要仕入予算
}

export class MdMapProductEntity {
  brand: Brand;
  category: Category;
  product: Product;
  customTags: CustomTag[];
  orderProductId: number;
  brandId: number; // TO BE REMOVED
  categoryId: number; // TO BE REMOVED
  brandCategoryId: string;
  productId: string;
  row: number;
  column: number;
  // buyingQuantityResult: number; // 仕入数実績
  buyingQuantityBudget: number; // 仕入数計画済予算
  stockedDate: Date; // (倉庫への)納品予定日: OrderProduct.stockedDate
  weightedAverageCost: number; // 該当品番の原価の加重平均 = AVG(orderQuantity * cost)
  orderInstructionId: string | null;
}

export class ProductWithOrderProductsEntity extends Product {
  orderProducts: OrderProduct[];
}

export class SkuWithOrderSkusEntity extends Sku {
  orderSkus: OrderSku[];
}

export class UpsertOrderSkuFromExistingOrderProductEntity {
  skus: SkuWithCostEntity[];
}

export class SkuWithCostEntity {
  skuId: string;
  oldCost: number;
  newCost: number;
}

export class MdMapSeasonTotalBuyingInfoEntity {
  yearMonth: string; // 例: 21/5
  info: MdMapStatsTotalEntity;
}

export class MdMapSeasonTotalItemRatioEntity {
  countTotal: number; //企画品番数合計
  amountTotal: number; //投入金額合計
  countByStatus: ItemRatioStatusDetailEntity[];
  countByBrand: ItemRatioDetailEntity[];
  countByCategory: ItemRatioDetailEntity[];
  countByAttribute1: ItemRatioDetailEntity[];
  countByAttribute2: ItemRatioDetailEntity[];
  countByAttribute3: ItemRatioDetailEntity[];
}

export class ItemRatioStatusDetailEntity {
  id: string; // ProductStatusのstringを返す
  countRate: number; // 品番数の割合
  amountRate: number; // 投入金額の割合。ver4.15では0として返す
}

export class ItemRatioDetailEntity {
  id: string | number;
  name: string;
  countRate: number; // 品番数の割合
  amountRate: number; // 投入金額の割合
}

export class MdMapSeasonTotalGroupListEntity {
  id: string | number;
  name: string;
  dates: MonthSaleInfoEntity[];
}

export class MonthSaleInfoEntity {
  yearMonth: string; // 例: 21/5
  count: number;
  amount: number;
}

export class MdMapDeliveryProductEntity {
  brand: Brand;
  category: Category;
  product: Product;
  customTags: CustomTag[];
  brandId: number;
  categoryId: number;
  brandCategoryId: string;
  productId: string;
  salesStartedAt: Date; // 販売開始日
  price: number; // 定価
  deliveryCount: number; // 初期投入数
  weightedAverageCost: number; // 該当品番の原価の加重平均 = AVG(orderQuantity * cost)
}

export class MdMapAdditionalProductEntity {
  brand: Brand;
  category: Category;
  product: Product;
  customTags: CustomTag[];
  brandId: number;
  categoryId: number;
  brandCategoryId: string;
  productId: string;
  salesStartedAt: Date; // 販売開始日
  price: number; // 定価
  additionalCount: number; // 追加発注数
  weightedAverageCost: number; // 該当品番の原価の加重平均 = AVG(orderQuantity * cost)
}

export class MdMapStatsDelioveryAdditionalEntity {
  deliveryBuyingBudget: number; // 投入品番ベースの予算
  additionalBuyingBudget: number; // 追加発注品番ベースの予算
  neededBuyingBudget: number; // 必要仕入予算
}

export class MdMapProduct {
  // productの情報をまとめた型
  product: Product;
  weightedAverageCost: number; // SKUの原価を加重平均した品番の原価
  productCustomValues: ProductCustomValueEntity[];
  orderProducts: OrderProductInfo[]; // orderProduct.stockedDateでdsort
  skus: SkuWithCustomValue[]; // color, sizeの順にsort
}
export class SkuWithCustomValue {
  sku: Sku;
  skuCustomValues: SkuCustomValueEntity[];
}

export class OrderProductInfo {
  orderProduct: OrderProduct;
  orderSkus: OrderSku[]; // color, sizeの順にsort
}

export class OrderInstructionSearchResultEntity {
  orderInstructionId: string;
  warehouseId: string;
  orderNumber: string;
  factoryId: string;
  orderedDate: Date;
  stockedDate: Date;
  title: string;
  creatorAdminUserId: string;
  managerAdminUserId: string;
  status: OrderProductStockedStatus;
  orderSkuCount: number;
  orderTotalCount: number;
  orderTotalAmount: number;
}

export class OrderInstructionDetailEntity {
  orderProductId: number;
  productId: string;
  orderSkus: OrderInstructionSkuEntity[];
}

export class OrderInstructionSkuEntity {
  orderSkuId: number;
  skuId: string;
  orderCost: number;
  orderQuantity: number;
}

export class OrderProductSearchResultEntity {
  orderProductId: number;
  productId: string;
  productName: string;
  itemNumber: string;
  orderedDate: Date;
  stockedDate: Date;
  stockedStatus: OrderProductStockedStatus;
  orderSkuCount: number; // orderProductに対するorderSkuのレコード数
  skuCount: number; // productに対する論理削除されていないskuのレコード数
  orderTotalCount: number;
}
export class ProductDetailCommentEntity {
  itemNumber: string;
  positivity: number;
  category: number;
  content: string;
  servedAt: string;
  age: string;
  gender: string;
  target: number;
  figure: string;
  height: string;
  skuId: string | null;
  commentId: string;
  colorName: string | null;
  sizeName: string | null;
  storeName: string | null;
  storeId: string | null;
  memberName: string | null;
  memberId: string | null;
  operating: boolean;
}

export class SkuWithColorSize extends Sku {
  color: Color;
  size: Size;
  skuCustomValues: SkuCustomValueEntity[];
}
export class ProductDetailBasicInfoEntity extends Product {
  skus?: SkuWithColorSize[] = [];
  brand?: { name: string };
  category?: { name: string };
  season?: { name: string };
  attribute1?: { name: string };
  attribute2?: { name: string };
  attribute3?: { name: string };
  factory?: { name: string };
  productCustomValues: ProductCustomValueEntity[];
}

export class ProductCustomValueEntity {
  productCustomValueSettingId: string;
  productCustomValueSettingName: string;
  productCustomValueSettingType: CustomValueType;
  productCustomValueId: string;
  productCustomValueName: string;
}

export class SkuCustomValueEntity {
  skuCustomValueSettingId: string;
  skuCustomValueSettingName: string;
  skuCustomValueSettingType: CustomValueType;
  skuCustomValueId: string;
  skuCustomValueName: string;
}

export class ProductDetailAlertData {
  alertMasterId: number;
  userDeleted: boolean;
  id: string;
}

export class ProductDetailTrendChartEntity {
  // ProductId or SkuId <= ProductDetailTrendTableEntityとの結びつけ
  id: string;
  digestionChartData: ChartData[]; // x: yyyy-MM-DD, y: 消化率
}
export class ChartData {
  x: string;
  y: number;
}
export class StoreTrendData {
  storeName: string;
  salesCounts: number[]; // 0week, -1week, -2week, -3week, -4week
  salesPrices: number[]; // 0week, -1week, -2week, -3week, -4week
  stockCount: number;
  digestionRate: string;
  storageLife: string;
}

export class WarehouseTrendData {
  warehouseName: string;
  stockCount: number;
}

export class ProductDetailSalesDigestRateChartEntity {
  productId: string;
  productData: ProductDetailSalesDigestRateChartData | null; // 品番のデータ
  skuData: ProductDetailSalesDigestRateChartData[]; // skuごと(Max10件)
}

export class ProductDetailSalesDigestRateChartData {
  id: string; // Product.id or Sku.id
  data: ProductDetailSalesDigestRateChartDataPoint[];
  totalSalesPrice: number;
  totalSalesCount: number;
  posSalesPrice: number;
  posSalesCount: number;
  ecSalesPrice: number;
  ecSalesCount: number;
  digestionRate: number;
  storageLife: string;
  stockCount: number;
  discountRate: number; // 値引き率
  grossProfitMargin: number; // 粗利率
}

export class ProductDetailSalesDigestRateChartDataPoint {
  date: Date;
  salesPrice: number;
  salesQuantity: number;
  digestRate: number;
}

export class ProductDetailTrendEntity {
  productId: string;
  items: DetailTrendItemEntity[];
  total: DetailTrendItemEntity;
}

export class DetailTrendItemEntity {
  storeId: string | null; // ProductDetailTrendEntity.totalにおけるstoreIdは"total"を想定
  warehouseId: string | null;
  salesCounts: number[]; // 直近1年間, 0week, -1week, -2week, -3week, -4week
  salesPrices: number[]; // 直近1年間, 0week, -1week, -2week, -3week, -4week
  stockCount: number;
  orderedStockCount: number;
  digestionRate: string;
  storageLife: string;
}

export class SkuDetailTrendEntity {
  skuId: string;
  items: DetailTrendItemEntity[];
  total: DetailTrendItemEntity;
}

export class ProductDetailActionInfoEntity {
  requests: StockActionInfoEntity[];
  pending: ActionInfoEntity;
  completed: OrderedActionInfoEntity[]; // executedDateの順
}

export class ActionInfoEntity {
  saleActions: SaleActionInfoEntity[];
  customActions: CustomActionInfoEntity[];
  stockActions: StockActionInfoEntity[];
}

export class OrderedActionInfoEntity {
  saleActions: SaleActionInfoEntity[];
  customActions: CustomActionInfoEntity[];
  stockActions: StockActionInfoEntity[];
  executedDate: Date; // executdDateでまとめる
  managerAdminUserId: string;
}

export class SaleActionInfoEntity {
  actionId: string;
  saleActionDetailId: string;
  oldSalesPrice: number;
  newSalesPrice: number;
  executedDate: Date | null;
}

export class CustomActionInfoEntity {
  actionId: string;
  customActionId: string;
  executedDate: Date | null;
}

export class StockActionInfoEntity {
  skuId: string;
  stockActionDetails: StockActionDetailInfoEntity[];
}

export class StockActionDetailInfoEntity {
  actionId: string | null;
  executedDate: Date | null;
  stockMovingInfos: StockActionDetailInfo[];
  stockFollowInfos: StockActionDetailInfo[];
}

export class StockActionDetailInfo {
  stockActionDetailId: string;
  sourceId: string;
  destinationId: string;
  quantity: number;
}

//レスポンスEntity
export class ProductListEntity {
  productListDatas: ProductListDataEntity[];
  productMaxCount: number;
  productSalesStockInfo: ProductSalesStockInfoEntity;
  productFilterInfo: ProductFilterEntity;
}

export class ProductListDataEntity {
  productId: string;
  name: string | null;
  itemNumber: string;
  price: number | null;
  salesStartedAt: Date | null;
  salesEndedAt: Date | null;
  salesPrice: number;
  stockCount: number;
  sumComment: number;
  countPositiveComment: number;
  countNegativeComment: number;
  sumTransactionQuantity: number;
  sumTransactionDiscountSubtotal: number;
  digestionRate: number;
  discountRate: number;
  storageLife: number; //9999999999の時は無限を表す
  alertDatas: AlertWithMaster[];
  customTagInfo: CustomTagInfo[];
  actionData: ActionData[];
}

export class CustomTagInfo {
  customTagId: number;
  customTagName: string;
  icon: string; // creatorRoleIdが一致するCompany/AdminUserのicon(S3のpath)
}

export class AlertWithMaster {
  alertId: string;
  userDeleted: boolean;
  alertMaster: AlertMaster;
}

//フィルター条件Entity
export class ProductListInputEntity {
  start: Date;
  end: Date;
  offset: number;
  limit: number;
  sort: string;
  isDesc: boolean;
  itemNumber: string[] | null;
  brand: number[] | null;
  season: number[] | null;
  category: number[] | null;
  attribute1: string[] | null;
  attribute2: string[] | null;
  attribute3: string[] | null;
  status: ProductStatus[] | null;
  salesStatus: number[] | null;
  salesPrice: { min: number | null; max: number | null } | null;
  stockPrice: { min: number | null; max: number | null } | null;
  salesCount: { min: number | null; max: number | null } | null;
  stockCount: { min: number | null; max: number | null } | null;
  productSalesPrice: { min: number | null; max: number | null } | null;
  alerts: number[];
  customTagMasters: number[];
  store: string[] | null; // 非稼働店舗はinoperative
}

export class CustomTagData {
  customTagId: number;
  customTagMasterId: number;
  name: string;
}
//フィルター一覧取得Entity
export class ProductFilterInfoEntity {
  brands: FilterInfoDataEntity[];
  seasons: FilterInfoDataEntity[];
  categories: FilterInfoDataEntity[];
  attribute1: FilterInfoDataEntity[];
  attribute2: FilterInfoDataEntity[];
  attribute3: FilterInfoDataEntity[];
  customTagDatas: CustomTagData[];
  stores: FilterInfoDataEntity[];
}

export class FilterInfoDataEntity {
  id: number | string;
  name: string;
  code: string | null;
}
//売上、在庫の上限下限検索条件Entity
export class ProductSalesStockInfoEntity {
  salesPrice: number[];
  salesCount: number[];
  stockPrice: number[];
  stockCount: number[];
}

//検索結果のproduct情報Entity
export class ProductFilterEntity {
  salesPrice: number[];
}

export const SALES_STATUS = {
  NOT: 2, //未販売
  END: 1, //販売終了
  NOW: 0 // 販売中
};

export class ProductListActionAddProductsDto {
  actionId: string;
  productIds: string[] | null; // すべてのProductに対して実行する場合はnull
  offRate?: number; // セール以外のときはundefinedにする
}

export class ProductListDeleteProductsDto {
  productIds: string[] | null; // すべてのProductに対して実行する場合はnull
}
export class ResultDownloadEntity {
  S3Key: string;
}

// 合計
export class SalesBuyingPlanTotalEntity {
  seasonPlanId: number;
  saleBudget: number; //売上予算
  saleResult: number; //売上実績
  grossMarginBudget: number; // 粗利予算
  grossMarginResult: number; //粗利実績
  properSaleBudget: number; //プロパー売上予算
  properSaleResult: number; // プロパー売上実績
  bargainSaleBudget: number; //セール売上予算
  bargainSaleResult: number; // セール売上実績
  properRateTarget: number; //目標プロパー売上構成比
  offRateTarget: number; //目標OFF率
  priceSalesResultBudget: number; //定価売上予算
  openingStock: number; //期首在庫
  neededBuyingBudget: number; //必要仕入予算
  endingStock: number; //期末在庫
  yearMonth: string;
}

//予算 ブランド＊カテゴリ

export class SalesBuyingPlanBrandCategoryEntity {
  brandCategoryId: string; //ブランド内のカテゴリー合計の場合は"total"とする
  seasonBrandCategoryId: number | string; //ブランド内のカテゴリー合計の場合は"total"とする
  brand: Brand;
  category: Category | null; //ブランド内のカテゴリー合計の場合はnullとする
  digestRateTarget: number; //目標消化率（ブランド内のカテゴリー合計の場合は、サーバー側で計算して返す）
  resultInfo: SalesBuyingPlanResultInfoEntity[];
}

export class SalesBuyingPlanResultInfoEntity {
  seasonBrandCategoryPlanId: number | string;
  yearMonth: string;
  brandCategoryRate: number; //売上構成比（旧アイテム構成比）
  saleBudget: number; //売上予算
  saleResultTotal: number; //売上実績
  grossMarginBudget: number; // 粗利予算（ver5.1.1では0で返す）
  grossMarginResult: number; //粗利実績
  priceSalesResultBudget: number; //定価売上予算
  openingStock: number; //期首在庫
  buyingRate: number; //仕入れ構成比
  neededBuyingBudget: number; //必要仕入予算
  endingStock: number; //期末在庫
}

export class SalesBuyingPlanMonthBudgetTotalRateEntity {
  seasonTotalRate: SalesBuyingPlanMonthBudgetRateEntity[];
  brandRate: SalesBuyingPlanMonthBudgetBrandRateEntity[];
}

export class SalesBuyingPlanMonthBudgetBrandRateEntity {
  brandId: number;
  brandTotalRate: SalesBuyingPlanMonthBudgetRateEntity[];
  categoryRate: SalesBuyingPlanMonthBudgetCategoryRateEntity[];
}

export class SalesBuyingPlanMonthBudgetCategoryRateEntity {
  categoryId: number;
  categoryRate: SalesBuyingPlanMonthBudgetRateEntity[];
}

export class SalesBuyingPlanMonthBudgetRateEntity {
  yearMonth: string;
  rate: number;
}

export class SeasonsSummaryEntity {
  seasonId: number;
  seasonPlan: SeasonPlanSummaryEntity;
  result: MdMapSummaryEntity;
}

export class SeasonPlanSummaryEntity {
  saleResult: number;
  saleBudget: number;
  properDigestRate: number;
  digestRate: number;
}

export class MdMapSummaryEntity {
  plannedBuyingRate: number;
  statusRate: ItemRatioStatusDetailEntity[];
  productCount: number;
}

//フィルター一覧取得Entity
export class SkuFilterInfoEntity {
  brands: FilterInfoDataEntity[];
  seasons: FilterInfoDataEntity[];
  categories: FilterInfoDataEntity[];
  attribute1: FilterInfoDataEntity[];
  attribute2: FilterInfoDataEntity[];
  attribute3: FilterInfoDataEntity[];
  stores: FilterInfoDataEntity[];
  colors: FilterInfoDataEntity[];
  sizes: FilterInfoDataEntity[];
  alertMasters: FilterInfoDataEntity[];
}

export class SkuListEntity {
  skuListDatas: SkuListDataEntity[];
  skuMaxCount: number;
}

export class SkuListDataEntity {
  productId: string;
  skuId: string;
  productName: string | null;
  itemNumber: string;
  salesStartedAt: Date | null;
  salesEndedAt: Date | null;
  colorName: string;
  sizeName: string;
  sumSalesPrice: number; // 売上金額
  sumSalesCount: number; // 売上枚数
  sumStoreStockPrice: number; // 店舗在庫金額
  sumStoreStockCount: number; // 店舗在庫枚数
  sumWarehouseStockPrice: number; // 倉庫在庫金額
  sumWarehouseStockCount: number; // 倉庫在庫枚数
  digestionRate: number;
  storageLife: number;
  alertDatas: AlertWithMaster[];
  actions: SkuActionData[];
  requests: SkuRequestData[];
}

export class ActionData {
  id: string; // Action.id
  type: string; // ActionType === 'SALE'なら"セール", ActionType === 'CUSTOM'なら、Action.customActionMasterIdがCustomActionMaster.idと一致するカスタムアクションマスタのCustomActionMaster.name
  title: string; // Action.title
  icon: string; // managerRoleIdが一致するCompany/AdminUserのicon(S3のpath)
  status: ActionStatus;
}

export class SkuActionData {
  actionId: string;
  type: SkuActionType; // SkuActionType === 'STOCKFOLLOW'なら"在庫フォロー", SkuActionType === 'STOCKMOVING'なら在庫移動
  title: string; // Action.title
  icon: string; // managerRoleIdが一致するCompany/AdminUserのicon(S3のpath)、creatorStoreIdにデータがある場合は空文字列を返す
}

export class SkuRequestData {
  stockActionDetailId: string;
  type: SkuActionType; // SkuActionType === 'STOCKFOLLOW'なら"在庫フォロー", SkuActionType === 'STOCKMOVING'なら在庫移動
  icon: string; // managerRoleIdが一致するCompany/AdminUserのicon(S3のpath)、creatorStoreIdにデータがある場合は空文字列を返す
  sourceStoreId: string;
  sourceWarehouseId: string;
  destinationStoreId: string;
  quantity: number;
}

export class SkuActionRequestData {
  stockActionDetailId: string;
  type: SkuActionType; // SkuActionType === 'STOCKFOLLOW'なら"在庫フォロー", SkuActionType === 'STOCKMOVING'なら在庫移動
  icon: string; // managerRoleIdが一致するCompany/AdminUserのicon(S3のpath)、creatorStoreIdにデータがある場合は空文字列を返す
}

export enum SkuActionType {
  STOCKMOVING = 'STOCKMOVING',
  STOCKFOLLOW = 'STOCKFOLLOW',
  STOCKMOVINGREQUEST = 'STOCKMOVINGREQUEST',
  STOCKFOLLOWREQUEST = 'STOCKFOLLOWREQUEST',
  CUSTOM = 'CUSTOM'
}

export class ReportCommentEntity {
  countComments: number;
  sumPositiveCommentsByProductId: number | null;
  sumNegativeCommentsByProductId: number | null;
  sumNeutralCommentsByProductId: number | null;
}

export class ReportSalesProductEntity {
  janCode: string;
  productId: string;
  productName: string;
  itemNumber: string;
  sumQuantity: number;
  countComments: number;
}

export class ReportStoreCommentEntity {
  itemNumber: string;
  productId: string;
  positivity: number;
  category: number;
  content: string;
  servedAt: string;
  age: string;
  gender: string;
  target: number;
  figure: string;
  height: string;
  skuId: string | null;
  skus: Sku[];
  commentId: string;
  colorName: string | null;
  sizeName: string | null;
  storeName: string | null;
  storeId: string | null;
  memberName: string | null;
  memberId: string | null;
  operating: boolean;
}

export class StoreActualsGraphEntity {
  items: StoreActualsGraphItemEntity[];
}

export class StoreActualsGraphItemEntity {
  date: string;
  saleResult: number;
  saleBudget: number;
  temperature: number;
  unitPrice: number;
  purchaseRate: number;
  purchasersCount: number;
  setRate: number;
}

export class StoreActualsRankingSaleEntity {
  items: StoreActualsRankingSaleItemEntity[];
  total: StoreActualsRankingSaleItemEntity;
}

export class StoreActualsRankingSaleItemEntity {
  storeId: string;
  saleResult: number;
  saleBudget: number;
  share: number;
}

export class StoreActualsRankingOtherEntity {
  items: StoreActualsRankingOtherItemEntity[];
  total: StoreActualsRankingOtherItemEntity;
}

export class StoreActualsRankingOtherItemEntity {
  storeId: string;
  unitPrice: number;
  purchaseRate: number;
  purchasersCount: number;
  setRate: number;
}

export class StoreActualsDailyReportEntity {
  items: StoreActualsDailyReportItemEntity[];
}

export class StoreActualsDailyReportItemEntity {
  storeAnalysisId: string;
  storeId: string;
  memberId: string | null;
  saleResult: number;
  purchasersCount: number;
  customersCount: number;
  commentsCount: number;
}
export class StoreSeasonPlanTotalEntity {
  total: TotalTotalMonthEntity[]; // 合計の全店舗
  seasons: StoreSeasonEntity[]; // シーズンの全店舗
}

// 合計の全店舗-各月の数値
export class TotalTotalMonthEntity {
  saleBudget: number; // 売上予算
  saleResult: number; // 今季 売上実績
  lastYearSaleResult: number; //昨年売上実績
  yearMonth: string; // 0番目が一番古い日付
}

// シーズン分
export class StoreSeasonEntity {
  seasonId: number;
  refSeasonId: number;
  totalMonths: SeasonTotalMonthEntity[];
}

// シーズンの全店舗-各月の数値
export class SeasonTotalMonthEntity {
  saleBudget: number; // 売上予算
  saleResult: number; // 今季 売上実績
  refSeasonSaleResult: number; // 参照シーズン売上実績
  yearMonth: string; // 0番目が一番古い日付
  budgetSeasonRateId: string; // BudgetSeasonRateテーブルのid
  rate: number; // 月別売上予算構成比
}

// 合計-店舗
export class StoreSeasonPlanSeasonTotalEntity {
  storeId: string;
  storeMonths: TotalTotalMonthEntity[];
}

export class StoreSeasonSaleBudgetInfoDto {
  storeSaleBudgets: StoreSeasonBudgetDto[]; // 合計の店舗別情報をまだ取得していない場合は空配列とする
  rates: StoreSeasonRateDto[]; // シーズンの開始月の月別売上予算構成比を先頭に
}

export class StoreSeasonRateDto {
  yearMonth: string; // シーズンのマスタ期間に含まれない年月もリクエストに含めるが、rateを0とする。
  rate: number;
}

export class StoreSeasonBudgetDto {
  storeId: string;
  saleBudgets: StoreSeasonMonthBudgetDto[];
}

export class StoreSeasonMonthBudgetDto {
  yearMonth: string;
  saleBudget: number;
}

// 全合計が押されたタイミングで取得する合計または該当シーズンにおける店舗の売上計画情報。配列で返す
// シーズン-店舗
export class StoreSeasonPlanStoreEntity {
  storeId: string;
  storeMonths: StoreMonthEntity[];
}

// シーズン-店舗-各月の数値
export class StoreMonthEntity {
  saleBudget: number; // 売上予算
  saleResult: number; // 今季 売上実績
  refSeasonSaleResult: number; // 参照シーズン売上実績
  yearMonth: string; // 0番目が一番古い日付
}
export class AuthResult {
  status: number;
  message: string;
  auth: boolean;
}
export class ShopifyStoreEntity {
  locationName: string;
  posId: string; //locationId
  posEcWarehouseStatus: number; //未選択:0 pos:1  warehouse:3
}

export class StatusResultEntity {
  status: number;
  message: string;
}
export class SmaregiStore {
  id: string;
  code: string;
  name: string;
  abbr: string;
}

export interface SmaregiStoreDetail {
  storeId: string;
  storeCode: string;
  storeName: string;
  storeAbbr: string;
  printReceiptStoreName: string;
  printStockReceiptStoreName: string;
  division: string;
  postCode: string;
  address: string;
  phoneNumber: string;
  faxNumber: string;
  mailAddress: string;
  homepage: string;
  tempTranMailAddress: string;
  priceChangeFlag: string;
  sellDivision: string;
  sumProcDivision: string;
  sumDateChangeTime: string;
  sumRefColumn: string;
  pointNotApplicable: string;
  taxFreeDivision: string;
  maxBundleProductCount: string;
  maxDiscountRate: string;
  carriageDisplayFlag: string;
  terminalAdjustmentCashFlag: string;
  terminalCheckCashFlag: string;
  waiterAdjustmentDivision: string;
  savingAutoDivision: string;
  savingAutoPrice: string;
  cancelSettingDivision: string;
  roundingDivision: string;
  discountRoundingDivision: string;
  cardCompanySelectDivision: string;
  giftReceiptValidDays: string;
  taxLabelNormal: string;
  taxLabelReduce: string;
  pauseFlag: string;
  displaySequence: string;
  facePaymentUseDivision: string;
  insDateTime: string;
  updDateTime: string;
  pointCondition: any;
  receiptPrintInfo: any;
}

export class SmaregiFreeLabel {
  reserveItemsCode: string;
  labelName: string;
}

export class SmaregiAPIFreeLabel {
  no: string;
  label: string;
}

export class SmaregiProduct {
  productId: string;
  categoryId: string;
  productCode: string;
  productName: string;
  productKana: string;
  taxDivision: string;
  productPriceDivision: string;
  price: string;
  customerPrice: string;
  cost: string;
  attribute: string;
  description: string;
  catchCopy: string;
  size: string;
  color: string;
  tag: string;
  groupCode: string;
  url: string;
  printReceiptProductName: string;
  displaySequence: string;
  salesDivision: string;
  stockControlDivision: string;
  displayFlag: string;
  division: string;
  productOptionGroupId: string;
  pointNotApplicable: string;
  taxFreeDivision: string;
  supplierProductNo: string;
  calcDiscount: string;
  staffDiscountRate: string;
  useCategoryReduceTax: string;
  reduceTaxId: string;
  reduceTaxPrice: string;
  reduceTaxCustomerPrice: string;
  orderPoint: string;
  purchaseCost: string;
  appStartDateTime: string;
  insDateTime: string;
  updDateTime: string;
}
export type CustomValueType = 'STRING' | 'NUMBER' | 'DATE';

export type CompanyPlan = 'STANDARD' | 'STORE' | 'MD' | 'FULL';

export type AlertPlan = 'STANDARD' | 'PREMIUM';

export type SmaregiCategory =
  | 'BRANDCODE'
  | 'CATEGORYCODE'
  | 'ITEMNUMBER'
  | 'JANCODE'
  | 'ATTRIBUTE1NAME'
  | 'ATTRIBUTE1CODE'
  | 'ATTRIBUTE2NAME'
  | 'ATTRIBUTE2CODE'
  | 'ATTRIBUTE3NAME'
  | 'ATTRIBUTE3CODE';

export type SmaregiUnitPrice = 'PRICE' | 'SALESPRICE';

export type SmaregiProductNumber = 'JANCODE' | 'ITEMNUMBERCOLORCODESIZECODE' | 'ITEMNUMBERSIZECODECOLORCODE' | 'EMPTY';

export type FutureshopJanCodeRecognitionKey = 'JANCODE' | 'SKUNO';

export type ShopifyRadialConnection =
  | 'JANCODE'
  | 'ITEMNUMBERCOLORCODESIZECODE'
  | 'ITEMNUMBERSIZECODECOLORCODE'
  | 'BRANDNAME'
  | 'BRANDCODE'
  | 'CATEGORYNAME'
  | 'CATEGORYCODE'
  | 'ITEMNAME'
  | 'ITEMNUMBER'
  | 'SALESPRICE'
  | 'PRICE'
  | 'COST'
  | 'SALESSTARTEDAT'
  | 'SALESPRICEUPDATEDAT'
  | 'DESCRIPTION'
  | 'COLORCODE'
  | 'COLORNAME'
  | 'SIZECODE'
  | 'SIZENAME'
  | 'ATTRIBUTE1'
  | 'ATTRIBUTE2'
  | 'ATTRIBUTE3';

export type BaseJanCodeRecognitionKey = 'BARCODE' | 'VARIATIONIDENTIFIER';

export type MakeshopJanCodeRecognitionKey = 'JANCODE' | 'ORIGINALCODE';

export type NextEngineJanCodeRecognitionKey = 'JANCODE' | 'GOODSID';

export type LogizardJanCodeRecognitionKey = 'JANCODE' | 'ITEMNUMBER' | 'COLORCODE' | 'SIZECODE';

export type ShopifyJanCodeRecognitionKey = 'SKU' | 'BARCODE';

export type ProductStatus = 'PLANNING' | 'FIRSTSAMPLE' | 'SECONDSAMPLE' | 'DROP' | 'ORDERED';

export type ProductStore = 'ALL' | 'STORE' | 'EC';

export type OrderInstructionStatus = 'PREORDER' | 'ORDERED' | 'PARTLYSTOCKED' | 'STOCKED' | 'DROP';

export type OrderProductStockedStatus = 'PREORDER' | 'ORDERED' | 'STOCKED' | 'DROP';

export type ServiceType = 'POS' | 'EC' | 'WMS';

export type ActionType = 'SALE' | 'CUSTOM' | 'STOCKMOVING' | 'STOCKFOLLOW' | 'INTERSTORETRANSFER';

export type ActionStatus = 'PENDING' | 'COMPLETED';

export type SmaregiOptionType =
  | 'PRODUCTKANA'
  | 'TAXDIVISION'
  | 'PRODUCTPRICEDIVISION'
  | 'CUSTOMERPRICE'
  | 'COST'
  | 'ATTRIBUTE'
  | 'ITEMNUMBER'
  | 'DESCRIPTION'
  | 'CATCHCOPY'
  | 'SIZE'
  | 'COLOR'
  | 'TAG'
  | 'GROUPCODE'
  | 'URL'
  | 'PRINTRECEIPTPRODUCTNAME'
  | 'DISPLAYSEQUENCE'
  | 'DISPLAYFLAG'
  | 'DIVISION'
  | 'PRODUCTOPTIONGROUPID'
  | 'SALESDIVISION'
  | 'STOCKCONTROLDIVISION'
  | 'POINTNOTAPPLICABLE'
  | 'TAXFREEDIVISION'
  | 'CALCDISCOUNT'
  | 'STAFFDISCOUNTRATE'
  | 'USECATEGORYREDUCETAX'
  | 'REDUCETAXID'
  | 'REDUCETAXPRICE'
  | 'REDUCETAXCUSTOMERPRICE'
  | 'ORDERPOINT'
  | 'PURCHASECOST'
  | 'SUPPLIERPRODUCTNO'
  | 'APPSTARTDATETIME'
  | 'RESERVEITEMS'
  | 'PRICES'
  | 'STORES'
  | 'INVENTORYRESERVATIONS'
  | 'ATTRIBUTEITEMS'
  | 'ORDERSETTING';

export type RadialConnection =
  | 'JANCODE'
  | 'ITEMNUMBERCOLORCODESIZECODE'
  | 'ITEMNUMBERSIZECODECOLORCODE'
  | 'BRANDNAME'
  | 'BRANDCODE'
  | 'CATEGORYNAME'
  | 'CATEGORYCODE'
  | 'ITEMNAME'
  | 'ITEMNUMBER'
  | 'SALESPRICE'
  | 'PRICE'
  | 'COST'
  | 'SALESSTARTEDAT'
  | 'SALESPRICEUPDATEDAT'
  | 'SALESENDEDAT'
  | 'DESCRIPTION'
  | 'COLORCODE'
  | 'COLORNAME'
  | 'SIZECODE'
  | 'SIZENAME'
  | 'COMPOSITION'
  | 'FREECOMMENT'
  | 'DIRECTIONMEMO'
  | 'FACTORYNAME'
  | 'FACTORYCODE'
  | 'ATTRIBUTE1NAME'
  | 'ATTRIBUTE1CODE'
  | 'ATTRIBUTE2NAME'
  | 'ATTRIBUTE2CODE'
  | 'ATTRIBUTE3NAME'
  | 'ATTRIBUTE3CODE'
  | 'SEASONNAME'
  | 'SEASONCODE'
  | 'CUSTOMPRODUCT'
  | 'CUSTOMSKU'
  | 'EMPTY';

export type ShopifyOptionType =
  | 'TITLE'
  | 'BODYHTML'
  | 'VENDOR'
  | 'PRODUCTTYPE'
  | 'HANDLE'
  | 'TAGS'
  | 'PRICE'
  | 'SKU'
  | 'COMPAREATPRICE'
  | 'OPTION1NAME'
  | 'OPTION1VALUE'
  | 'OPTION2NAME'
  | 'OPTION2VALUE'
  | 'OPTION3NAME'
  | 'OPTION3VALUE'
  | 'TAXABLE'
  | 'BARCODE'
  | 'REQUIRESSHIPPING'
  | 'INVENTORYMANAGEMENT';
